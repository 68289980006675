<template>
  <svg
    width="124"
    height="28"
    viewBox="0 0 124 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M114.101 4.21439C114.101 1.88666 115.961 9.53674e-07 118.256 9.53674e-07C120.551 9.53674e-07 122.413 1.88666 122.413 4.21439C122.413 6.54126 120.551 8.42792 118.256 8.42792C115.961 8.42792 114.101 6.54126 114.101 4.21439Z"
      :fill="props.color"
    />
    <path
      d="M89.803 25.0953L88.5786 25.7631C87.6389 26.2548 87.853 27.0794 88.7201 27.2375H98.4537C99.4992 27.2547 99.785 26.1324 98.5952 25.6927L97.6556 25.1503V0.669613C97.4967 0.0365734 97.1259 -0.137791 96.4507 0.212656L88.2599 4.17537C87.6398 4.58251 87.8973 5.26022 88.3133 5.43716L89.6597 5.98215C89.8738 6.04572 89.7566 6.26217 89.803 6.20934V25.0953Z"
      :fill="props.color"
    />
    <path
      d="M102.05 25.0953L100.823 25.7631C99.8835 26.2548 100.099 27.0794 100.966 27.2375H110.7C111.745 27.2547 112.032 26.1324 110.841 25.6927L109.901 25.1503V0.669184C109.743 0.0365734 109.37 -0.137791 108.696 0.212656L100.506 4.17537C99.8857 4.58251 100.143 5.26022 100.559 5.43716L101.905 5.98215C102.12 6.04572 102.002 6.26217 102.05 6.20934V25.0953Z"
      :fill="props.color"
    />
    <path
      d="M122.288 9.07869C122.128 8.44608 121.755 8.27128 121.081 8.6213L112.889 12.5844C112.269 12.9912 112.528 13.6693 112.942 13.8467L114.289 14.3917C114.504 14.4548 114.386 14.6717 114.432 14.6184V25.0953L113.208 25.7632C112.268 26.2549 112.482 27.0795 113.349 27.2371H123.083C124.129 27.2547 124.415 26.1325 123.224 25.6927L122.288 25.1503V9.07869Z"
      :fill="props.color"
    />
    <path
      d="M14.0562 10.6835C13.7957 10.994 12.7184 11.5966 11.3194 11.5966C10.6221 11.5966 9.99333 11.6005 9.99333 10.8377V5.20651C10.223 4.26554 12.7844 4.09977 13.6511 5.06135C14.1113 5.59862 14.6441 6.27074 14.6015 8.15181C14.6015 9.18211 14.615 10.1132 14.0562 10.6835ZM14.1626 22.7276C13.8577 23.1068 12.8791 23.5943 12.0458 23.5943C11.0094 23.5805 10.0454 23.4328 10.0454 22.5983V15.628C10.1336 15.0199 10.8643 14.8477 11.7128 14.8331C12.7783 14.8618 13.2859 14.9765 14.1773 15.8148C15.0831 16.8546 14.9202 18.1301 14.9958 19.2553C15.0106 20.8023 14.5355 22.4033 14.1626 22.7276ZM2.03609 24.5172C2.0756 25.0772 1.79076 25.1253 1.79076 25.1253L0.513332 25.785C-0.295591 26.2475 -0.113659 27.2095 0.879367 27.2697C-0.072844 27.2894 14.7392 27.2697 14.7392 27.2697C20.5719 27.2697 22.9057 22.6881 22.9057 19.4533C22.9678 16.5643 19.8424 13.4734 17.4729 13.381C17.38 13.3918 17.2906 13.1848 17.4933 13.1388C19.7039 13.0787 22.2158 10.6346 22.2158 7.54884C22.1537 4.48028 19.9258 1.35417 14.2316 1.05397H0.737816C-0.342051 1.04366 -0.0680674 2.30158 0.492925 2.53821C0.492925 2.53821 1.60102 3.13475 1.62707 3.17812C1.94968 3.38985 2.00396 3.27261 2.03609 3.7403V24.5172Z"
      :fill="props.color"
    />
    <path
      d="M65.1165 27.2373H67.1682C68.2146 27.2545 68.5003 26.1327 67.3097 25.6929L66.3701 25.1505V13.4109C66.3701 11.7467 65.7201 10.2226 64.708 9.1798C63.8756 8.29166 62.2143 7.16989 59.8331 7.16989C57.4237 7.23946 56.2892 7.77458 54.8129 8.78512C53.8906 9.44565 53.9896 8.95304 53.9701 8.66573L53.9683 7.08442C53.9045 6.56949 53.3261 6.31352 52.7504 6.62274L44.9356 10.3995C44.0754 10.8011 44.2517 11.5217 44.6703 11.7012L45.9286 12.2664C46.1765 12.3398 46.1379 12.6843 46.1379 12.6843V25.0951L44.8331 25.7634C43.8935 26.2547 44.1084 27.0792 44.9755 27.2373H54.7095C55.7555 27.2545 56.0412 26.1327 54.8506 25.6929L53.911 25.1505L53.8685 14.455C53.944 12.1316 55.6248 11.8485 56.5527 11.8485C57.7911 11.8455 58.7693 12.3617 58.8701 14.455V25.0951L57.6447 25.7634C56.7051 26.2547 56.9192 27.0792 57.7859 27.2373H65.1165Z"
      :fill="props.color"
    />
    <path
      d="M30.9093 14.9566C30.6905 13.4955 31.1846 10.5554 34.0074 10.1663C36.5805 10.0443 37.2652 12.1401 37.2344 13.2116C37.2344 13.4328 37.2583 13.5213 37.1771 13.5617L31.4729 15.5608C31.1264 15.7142 30.9093 15.3246 30.9093 14.9566ZM42.4596 20.2317C40.6459 21.7826 38.5731 23.0267 36.8757 22.955C34.4064 22.9391 32.5619 21.5193 32.0348 19.8731C32.007 19.8139 31.9992 19.7134 32.1143 19.685C32.2384 19.7409 43.6637 15.5621 43.6637 15.5621C44.0388 15.319 44.0145 15.1468 44.0375 14.6585C43.8556 12.2247 40.5339 6.44577 34.0829 6.71633C28.6484 6.77646 24.1609 11.5457 24.1397 17.3264C23.9694 21.5073 26.6481 27.7393 34.294 28C39.032 28.003 43.0467 24.8992 44.5794 21.7624C44.9502 20.2807 43.0584 19.5128 42.4596 20.2317Z"
      :fill="props.color"
    />
    <path
      d="M74.7109 14.9566C74.4898 13.4955 74.9861 10.5554 77.808 10.1663C80.3798 10.0443 81.0659 12.1401 81.0346 13.2116C81.0346 13.4328 81.0589 13.5213 80.9777 13.5617L75.2727 15.5608C74.928 15.7142 74.7109 15.3246 74.7109 14.9566ZM86.2594 20.2317C84.4479 21.7826 82.3737 23.0267 80.6764 22.955C78.2071 22.9391 76.3634 21.5193 75.8346 19.8731C75.8077 19.8139 75.7998 19.7134 75.9162 19.685C76.0382 19.7409 87.4643 15.5621 87.4643 15.5621C87.8395 15.319 87.8139 15.1468 87.8395 14.6585C87.6571 12.2247 84.3346 6.44577 77.8836 6.71633C72.4478 6.77646 67.9624 11.5457 67.9394 17.3264C67.771 21.5073 70.4483 27.7393 78.0955 28C82.8327 28.003 86.8464 24.8992 88.3788 21.7624C88.7509 20.2807 86.8599 19.5128 86.2594 20.2317Z"
      :fill="props.color"
    />
  </svg>
</template>

<script setup>
const props = defineProps({
  color: {
    type: String,
    required: false,
    default: "black",
  },
});
</script>

